<template>
  <v-app class="noselect" id="NeuralNetworks-c">
    <!-- v-btn block class="primary">1</v-btn> -->
    <!-- class="ma-0 pa-0" -->
    <v-container fill-height fluid>
      <v-layout
        row
        wrap
        justify-center
        align-xs-start
        align-sm-start
        align-md-start
        align-lg-center
        align-xl-center
        style="height: 103%"
      >
        <v-flex xs12 sm12 md12 lg6 xl6>
          <div
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in"
            data-aos-delay="200"
            data-aos-once="true"
          >
            <div id="videocontainer">
              <!-- <img src="../../assets/logo.png" style="border-radius:15px;margin-top:4px" width="100%" height="100%" /> -->
              <video
                controls
                width="100%"
                height="100%"
                playsinline
                autoplay
                loop
                muted
              >
                <source
                  src="../../assets/NeuralNetworkSimulation.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
        </v-flex>
        <v-flex xs12 sm12 md12 lg6 xl6>
          <div
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in"
            data-aos-delay="200"
            data-aos-once="true"
          >
            <div id="text">
              <p>
                Neural networks are fascinating mathematical methods that can
                learn any pattern by optimizing weights from a repeatedly simple
                activation function. However, the underlying learning processes
                are still being researched, which can make them sometimes feel
                like mysterious black-box models. That's why understanding the
                core objectives of model training and recognising data patterns
                is essential to effectively train and integrate machine learning
                models.
                <br />
                <br />
                For example, ChatGPT (Generative Pre-trained Transformer) is a
                probabilistic generative large language model trained in an
                autoregressive manner, where words are encoded as vectors,
                called embeddings. In the pre-training stage, the language model
                simply learns unsupervised to complete texts by masking
                embeddings and predicting next words, allowing it to tune the
                vectors to encode both semantic and syntactic relationships. In
                the fine-tuning stage, the model is aligned to specific tasks
                such as chat conversations, question answering, instuctions,
                i.e. summarising, enumeration, multiple-choice tasks, etc.. In
                the next stage, a reinforcement learning technique is used to
                train a second reward model that learns to score output phrases
                generated by the model that are annotated by humans. This stage
                ensures that model training can be accelerated in a
                semi-supervised manner and that not every piece of data needs to
                be manually labelled for further training. Recently, the model
                also processes multimodal (audio/image) data or, to some extent,
                searching the Web. Even the size of the context could be
                increased to 128,000 tokens within a single prompt. Current
                challenges that ChatGPT and other large language models still
                faces are arithmetic calculations or reliable facts, given that
                two-thirds of the pre-training data was scraped from the public
                Internet (e.g. Wikipedia or Reddit), which is outdated and
                mostly not trustworthy. To overcome this, methods such as
                Retrieval-Augmented Generation (RAG) can be employed to retrieve
                real-time data from (vector) databases.
                <br /><br />
                The video shows an example for image recognition.
                State-of-the-art computer vision models learn features from
                refined image pieces through combining convolutional filters in
                various ways. As AI continues to evolve, it's becoming a
                critical part of everyday workflows. I seek to stay up to date
                with the latest papers in the deep learning field and actively
                try to implement them (see:
                <a
                  id="link"
                  target="”_blank”"
                  href="https://github.com/KIlian42/AI-model-implementations"
                  >https://github.com/KIlian42/AI-model-implementations</a
                >).
              </p>
            </div>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: "NeuralNetworks-c",
  data: () => ({}),
  methods: {},
};
</script>
<style lang="scss" scoped>
::v-deep .v-application--wrap {
  min-height: fit-content;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
#link {
  color: #1dc4d0;
  text-decoration: none;
  pointer: cursor;
  &:hover {
    text-decoration: underline !important;
  }
}
#NeuralNetworks-c {
  position: relative;
  min-width: 100vw;
  min-height: 100vh;
  background-color: #151515;
}
#videocontainer {
  padding-left: 5%;
}
#text {
  text-align: justify;
  padding-left: 4%;
  padding-right: 20%;
  color: white;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  line-height: 160%;
}
@media (max-width: 1400px) {
  #text {
    font-size: 19px;
  }
}
@media (max-width: 1350px) {
  #text {
    font-size: 18px;
  }
}
@media (max-width: 1300px) {
  #text {
    font-size: 17px;
  }
}
@media (max-width: 1263px) {
  #videocontainer {
    margin-top: -0.5%;
    padding-left: 0%;
  }
  #text {
    font-size: 20px;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 15%;
    padding-bottom: 15%;
    text-align: left;
  }
}
@media (max-width: 1150px) {
  #text {
    padding-top: 7%;
    margin-bottom: -20px;
  }
}
@media (max-width: 650px) {
  #NeuralNetworks-c {
    min-height: 70vh;
  }
  #text {
    font-size: 16px;
    padding-top: 7%;
    padding-bottom: 40%;
  }
}
@media (max-width: 530px) {
  #text {
    font-size: 12px;
  }
}
@media (max-width: 400px) {
  #text {
    font-size: 12px;
    padding-left: 0%;
    padding-right: 0%;
    margin-top: -1%;
  }
}
@media screen and (orientation: landscape) and (max-width: 850px) and (max-height: 450px) {
  #NeuralNetworks-c {
    min-height: 80vh;
  }
  #text {
    padding-top: 5%;
    padding-bottom: 15%;
    font-size: 18px;
  }
}
</style>
